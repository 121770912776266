import { Button, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    HomeOutlined,
    TableOutlined,
    CarOutlined,
    BackwardOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../../api/auth';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    type?: 'group',
    children?: MenuItem[]
): MenuItem {
    return {
        key,
        icon,
        label,
        type,
        children,
    } as MenuItem;
}
export const sidebars: MenuItem[] = [
    getItem(<Link to='/'>Trang Chủ</Link>, 'home', <HomeOutlined />),

    getItem(
        <Link to='/products'>Quản lý sản phẩm</Link>,
        'products',
        <TableOutlined />
    ),

    getItem(<Link to='/brands'>Hãng xe</Link>, 'brands', <CarOutlined />),

    getItem(
        <Link to='/backup-products'>Khôi phục sản phẩm</Link>,
        'backup-products',
        <span className='relative'>
            <TableOutlined />
            <BackwardOutlined
                className='absolute text-[#ccc] left-[-4px]'
                style={{ fontSize: '13px' }}
            />
        </span>
    ),

    getItem(
        <Link to='/backup-brands'>Khôi phục hãng xe</Link>,
        'backup-brands',
        <span className='relative'>
            <CarOutlined />
            <BackwardOutlined
                className='absolute text-[#ccc] left-[-4px]'
                style={{ fontSize: '13px' }}
            />
        </span>
    ),
];

const Sidebar = () => {
    const [currentPage, setCurrentPage] = useState('');
    const location = useLocation();

    const handleChangePage: MenuProps['onClick'] = (e) => {
        setCurrentPage(e.key);
    };

    const handleLogout = async () => {
        try {
            await logout().then(
                () => {
                    window.location.reload();
                },
                (err) => {
                    console.log(err);
                }
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (sidebars) {
            sidebars.forEach((sidebar: any) => {
                if (location.pathname === sidebar.label.props.to) {
                    setCurrentPage(sidebar.key);
                }
                if (
                    location.pathname.includes(
                        '/add-product' || '/edit-product' || '/view-product'
                    )
                ) {
                    setCurrentPage('products');
                }
                if (
                    location.pathname.includes(
                        '/add-brand' || '/edit-brand' || '/view-brand'
                    )
                ) {
                    setCurrentPage('brands');
                }
            });
        }
    }, [location]);
    return (
        <>
            <Menu
                onClick={handleChangePage}
                selectedKeys={[currentPage]}
                defaultSelectedKeys={['home']}
                theme='dark'
                items={sidebars}
                className='w-[200px]'
            />
            <Button
                onClick={handleLogout}
                className='absolute bottom-[20px] right-[20px]'
            >
                Đăng xuất
            </Button>
        </>
    );
};

export default Sidebar;
