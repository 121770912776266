export function getTitleCase(str: string) {
    const titleCase = str
        .toLowerCase()
        .split(' ')
        .map((word: string) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');

    return titleCase;
}

export const changePriceValue = (price: number | null) => {
    if (price) {
        if (price >= 1000000 && price < 1000000000) {
            return price / 1000000 + ' triệu';
        }
        if (price >= 1000000000) {
            return price / 1000000000 + ' tỷ';
        } else {
            return price + '';
        }
    }
};

export const changeOdoValue = (odo: number | null) => {
    if (odo) {
        if (odo >= 10000) {
            return odo / 10000 + ' vạn';
        }
    }
};

