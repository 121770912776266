import { Navigate } from 'react-router-dom';
import { getCurrentUser } from '../api/auth';
import Login from '../components/Login';

const auth = getCurrentUser();

export const authRoute = [
    {
        path: 'login',
        element: auth ? <Navigate to='/' replace /> : <Login />,
    },
];
