import { Skeleton } from 'antd';

const DetailSkeleton = () => {
    return (
        <div className='flex justify-start gap-4 custom-skeleton'>
            <Skeleton.Input active block />
            <Skeleton.Input active block />
        </div>
    );
};

export default DetailSkeleton;
