import { RollbackOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Popconfirm, Space, Table } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { IProduct } from '../../models/product';

interface DeletedProductsProps {
    deletedProducts: IProduct[];
    setSearchKeyword?: Function;
    handleBackUpProduct: (key: number | string) => void;
}

type DataIndex = keyof IProduct;

const DeletedProducts: React.FC<DeletedProductsProps> = ({
    deletedProducts,
    handleBackUpProduct,
}) => {
    const tableData = deletedProducts?.map((product) => ({
        ...product,
        key: product?.id,
    }));
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex,
        title: string
    ): ColumnType<IProduct> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Tìm kiếm theo ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size='small'
                        style={{ width: 90 }}
                    >
                        Khôi phục
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            close();
                        }}
                    >
                        Đóng
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]!.toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<IProduct> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'ten',
            key: 'ten',
            sorter: (a, b) => {
                if (a.ten < b.ten) {
                    return -1;
                }
                if (a.ten > b.ten) {
                    return 1;
                }
                return 0;
            },
            width: '35%',
            ...getColumnSearchProps('ten', 'Tên'),
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            width: '15%',
        },
        {
            title: 'Biển số',
            dataIndex: 'bien_so',
            key: 'bien_so',
            width: '15%',
        },
        {
            title: 'Link Youtube',
            dataIndex: 'youtube_link',
            key: 'youtube_link',
            width: '15%',
        },
        {
            title: 'Giá',
            dataIndex: 'gia',
            key: 'gia',
            sorter: (a, b) => a.gia - b.gia,
            width: '15%',
        },
        {
            title: '',
            key: 'delete',
            fixed: 'right',
            width: 100,
            render: (record) => (
                <Space size='middle' className='ant-table-action'>
                    <Popconfirm
                        title={
                            <>
                                <p className='font-medium'>
                                    Khôi phục sản phẩm {record.ten}
                                </p>
                                <p>Bạn có chắc chắn muốn tiếp tục?</p>
                            </>
                        }
                        okText='Đồng ý'
                        cancelText='Không'
                        placement='left'
                        onConfirm={() => handleBackUpProduct(record?.id)}
                    >
                        <Button>
                            <RollbackOutlined />
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const data: IProduct[] = deletedProducts && tableData;

    return (
        <>
            <Table
                columns={columns}
                dataSource={data ? data : []}
                pagination={false}
            />
        </>
    );
};

export default DeletedProducts;
