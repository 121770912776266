import React from 'react';
import RouterManager from './routes';

function App() {
    return (
        <>
            <RouterManager />
        </>
    );
}

export default App;
