import { client } from './client';

export const backupProduct = async (key: number | string) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post(
        `/products/${key}`,
        {
            _method: 'patch',
        },
        {
            headers: { Authorization: 'Bearer ' + user.token },
        }
    );

    return res.data.data;
};

export const backupBrand = async (key: number | string) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post(
        `/brands/${key}`,
        {
            _method: 'patch',
        },
        {
            headers: {
                Authorization: 'Bearer ' + user.token,
                Accept: 'application/json',
            },
        }
    );
    return res.data.data;
};
