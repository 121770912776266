import { message, PaginationProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { deleteBrand, getBrandList } from '../../api/brand';
import NoDataResult from '../../components/common/500';
import PaginationComponent from '../../components/common/Pagination';
import Brands from '../../containers/brand';
import { IBrand } from '../../models/brand';

const BrandsPage = () => {
    const [brands, setBrands] = useState<IBrand[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState('');
    const { data, error } = useSWR(
        `brands?page=${page}&size=${pageSize}&searchKey=${searchKeyword}&isDeleted=false`,
        () => {
            return getBrandList(page, pageSize, searchKeyword, false);
        }
    );

    const handleShowSizeChange: PaginationProps['onShowSizeChange'] = (
        _current: number,
        pageSize: number
    ) => {
        setPageSize(pageSize);
        setTotalItem(data?.meta?.total_record);
    };

    const handleChangePage = useCallback((current: number) => {
        setPage(current);
    }, []);

    const handleDeleteBrand = async (key: string | number) => {
        setLoading(true);
        try {
            await deleteBrand(key).then(() => {
                message.success('Xóa sản phẩm thành công');
                setBrands((prev) => prev?.filter((item) => item.id !== key));
                setTotalItem(brands?.length);
                setLoading(false);
            });
        } catch (error) {
            message.error('Xóa sản phẩm thất bại');
        }
    };

    useEffect(() => {
        if (data) {
            setBrands(data?.result);
            setTotalItem(data.meta?.total_record);
        }
    }, [data]);

    useEffect(() => {
        if (data && !brands) {
            setBrands(data?.result);
        }
    }, [brands, data]);

    if (error) {
        return <NoDataResult />;
    }
    return (
        <>
            <>
                <Brands
                    brands={brands}
                    loading={loading}
                    setSearchKeyword={setSearchKeyword}
                    handleDeleteBrand={handleDeleteBrand}
                />
                <PaginationComponent
                    page={page}
                    totalItem={totalItem}
                    handleShowSizeChange={handleShowSizeChange}
                    handleChangePage={handleChangePage}
                    showSize={true}
                />
            </>
        </>
    );
};

export default BrandsPage;
