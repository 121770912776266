import { client } from './client';
import { message } from 'antd';

export const login = async (email: string, password: string) => {
    return client
        .post('/auth/login', {
            email,
            password,
        })
        .then((res) => {
            if (res.data.data.token) {
                localStorage.setItem('user', JSON.stringify(res.data.data));
            }
            window.location.reload();
            message.success(res.data.message);

            return res.data;
        })
        .catch((err) => message.error(err.response.data.message));
};

export const logout = async () => {
    localStorage.removeItem('user');
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user')!);
};
