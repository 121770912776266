import React from 'react';
import AddBrand from '../../containers/brand/Add';

const AddBrandPage = () => {
    return (
        <>
            <AddBrand />
        </>
    );
};

export default AddBrandPage;
