import { message, PaginationProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { backupProduct } from '../../api/backup';
import { getProductList } from '../../api/product';
import NoDataResult from '../../components/common/500';
import Loading from '../../components/common/Loading';
import PaginationComponent from '../../components/common/Pagination';
import DeletedProducts from '../../containers/backup-product';
import { IProduct } from '../../models/product';

const DeletedProductsPage = () => {
    const [deletedProducts, setDeletedProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState('');
    const { data, error } = useSWR(
        `products?page=${page}&size=${pageSize}&searchKey=${searchKeyword}&isDeleted=true`,
        () => {
            return getProductList(page, pageSize, searchKeyword, true);
        }
    );
    const handleShowSizeChange: PaginationProps['onShowSizeChange'] =
        useCallback((current: number, pageSize: number) => {
            setPageSize(pageSize);
        }, []);

    const handleChangePage = useCallback((current: number) => {
        setPage(current);
    }, []);

    const handleBackUpProduct = async (key: string | number) => {
        setLoading(true);
        try {
            await backupProduct(key).then(() => {
                message.success('Khôi phục hãng xe thành công');
                setDeletedProducts((prev) =>
                    prev?.filter((item) => item.id !== key)
                );
                setLoading(false);
            });
        } catch (error) {
            message.error('Khôi phục hãng xe thất bại');
        }
    };

    useEffect(() => {
        if (data) {
            setDeletedProducts(data?.result);
        }
    }, [data]);

    useEffect(() => {
        if (data && !deletedProducts) {
            setDeletedProducts(data?.result);
        }
    }, [deletedProducts, data]);

    if (error) {
        return <NoDataResult />;
    }
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <DeletedProducts
                        deletedProducts={deletedProducts}
                        setSearchKeyword={setSearchKeyword}
                        handleBackUpProduct={handleBackUpProduct}
                    />
                    <PaginationComponent
                        page={page}
                        totalItem={data?.meta?.total_record}
                        handleShowSizeChange={handleShowSizeChange}
                        handleChangePage={handleChangePage}
                        showSize={true}
                    />
                </>
            )}
        </>
    );
};

export default DeletedProductsPage;
