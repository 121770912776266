import { message, PaginationProps } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { backupBrand } from '../../api/backup';
import { getBrandList } from '../../api/brand';
import NoDataResult from '../../components/common/500';
import Loading from '../../components/common/Loading';
import PaginationComponent from '../../components/common/Pagination';
import DeletedBrands from '../../containers/backup-brand';
import { IBrand } from '../../models/brand';

const DeletedBrandsPage = () => {
    const [deletedBrands, setDeletedBrands] = useState<IBrand[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState('');
    const { data, error } = useSWR(
        `brands?page=${page}&size=${pageSize}&searchKey=${searchKeyword}&isDeleted=true`,
        () => {
            return getBrandList(page, pageSize, searchKeyword, true);
        }
    );
    const handleShowSizeChange: PaginationProps['onShowSizeChange'] =
        useCallback((current: number, pageSize: number) => {
            setPageSize(pageSize);
        }, []);

    const handleChangePage = useCallback((current: number) => {
        setPage(current);
    }, []);

    const handleBackUpBrand = async (key: string | number) => {
        setLoading(true);
        try {
            await backupBrand(key).then(() => {
                message.success('Khôi phục hãng xe thành công');
                setDeletedBrands((prev) =>
                    prev?.filter((item) => item.id !== key)
                );
                setLoading(false);
            });
        } catch (error) {
            message.error('Khôi phục hãng xe thất bại');
        }
    };

    useEffect(() => {
        if (data) {
            setDeletedBrands(data?.result);
        }
    }, [data]);

    useEffect(() => {
        if (data && !deletedBrands) {
            setDeletedBrands(data?.result);
        }
    }, [deletedBrands, data]);

    if (error) {
        return <NoDataResult />;
    }
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <DeletedBrands
                        deletedBrands={deletedBrands}
                        setSearchKeyword={setSearchKeyword}
                        handleBackUpBrand={handleBackUpBrand}
                    />
                    <PaginationComponent
                        page={page}
                        totalItem={data?.meta?.total_record}
                        handleShowSizeChange={handleShowSizeChange}
                        handleChangePage={handleChangePage}
                        showSize={true}
                    />
                </>
            )}
        </>
    );
};

export default DeletedBrandsPage;
