import React from 'react';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
            }}
        >
            <Outlet />
        </div>
    );
};

export default AuthLayout;
