import React from 'react';
import { Button, Form, Input } from 'antd';
import { login } from '../api/auth';

const Login: React.FC = () => {
    const onFinish = async (values: any) => {
        try {
            await login(values.email, values.password);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Form
            name='basic'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className='w-[88vw] max-w-[400px] mx-auto'
        >
            <Form.Item
                label='Email'
                name='email'
                rules={[{ required: true, message: 'Vui lòng nhập email !' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label='Mật khẩu'
                name='password'
                rules={[
                    { required: true, message: 'Vui lòng nhập mật khẩu !' },
                ]}
            >
                <Input.Password />
            </Form.Item>

            {/* <Form.Item
                name='remember'
                valuePropName='checked'
                wrapperCol={{ offset: 8, span: 16 }}
            >
                <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type='primary' htmlType='submit'>
                    Đăng nhập
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Login;
