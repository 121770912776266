import { message } from 'antd';
import { client } from './client';

export const getBrandList = async (
    page?: number,
    size?: number,
    searchKey?: string,
    isDeleted?: boolean
) => {
    const data = await client.get('/brands', {
        params: {
            page,
            size,
            searchKey,
            isDeleted,
        },
    });

    return data.data.data;
};

export const getBrandBySlug = async (slug: string | undefined) => {
    const res = await client.get(`/brands/${slug}`);

    return res.data.data;
};

export const createBrand = async (value: any) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post('/brands', value, {
        headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'multipart/form-data',
        },
    });

    if (res.data.status) {
        message.success(res.data.message);
    } else {
        message.error(res.data.message);
    }

    return res.data.data;
};

export const updateBrand = async (value: any, slug: string | undefined) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post(`/brands/${slug}`, value, {
        headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'multipart/form-data',
        },
    });

    if (res.data.status) {
        message.success(res.data.message);
    } else {
        message.error(res.data.message);
    }

    return res.data.data;
};

export const deleteBrand = async (key: number | string) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.delete(`/brands/${key}`, {
        headers: { Authorization: 'Bearer ' + user.token },
    });

    return res.data.data;
};
