import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Upload, UploadProps } from 'antd';
import { createBrand, getBrandBySlug, updateBrand } from '../../api/brand';
import { IBrand } from '../../models/brand';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import NoDataResult from '../../components/common/500';
import { Drawer } from '../product/Add';
import SpinComponent from '../../components/common/Spin';
import { getTitleCase } from '../../utils';
import BrandDetailSkeleton from '../../components/skeleton/BrandDetailSkeleton';
import BreadCrumb from '../../components/common/Breadcrumb';

const AddBrand = () => {
    const [images, setImage] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [textButton, setTextButton] = useState('Thêm hãng xe');
    const [type, setType] = useState<Drawer>({ type: 'add' });
    const formRef = useRef<any>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();

    const { data: dataDetail, error } = useSWR(`/brands/${slug}`, () => {
        if (slug) {
            return getBrandBySlug(slug);
        }
    });

    const onFinish = async (values: IBrand) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('ten', getTitleCase(values.ten));
        if (images) {
            if (images.length > 0) {
                formData.append('image', images[0].originFileObj);
            }
        }
        const body = formData;
        try {
            if (type.type === 'add') {
                try {
                    await createBrand(body);
                    setImage([]);
                    formRef?.current?.resetFields();
                } catch (err: any) {
                    message.error(err.message);
                    setLoading(false);
                }
            }
            if (type.type === 'edit') {
                formData.append('_method', 'PUT');
                try {
                    await updateBrand(body, dataDetail?.id);
                    navigate('/brands');
                } catch (err: any) {
                    message.error(err.message);
                    setLoading(false);
                }
            }
            setLoading(false);
        } catch (err: any) {
            message.error(err.message);
            setLoading(false);
        }
    };

    const props: UploadProps = {
        name: 'file',
        headers: { 'Content-Type': 'multipart/form-data' },
        onChange(info: { file: any; fileList: any }) {
            setImage(info.fileList);
        },
    };

    useEffect(() => {
        if (location.pathname.includes('edit-brand')) {
            setType({ type: 'edit' });
            setTextButton('Sửa hãng xe');
        }
        if (location.pathname.includes('add-brand')) {
            setType({ type: 'add' });
            setTextButton('Thêm hãng xe');
        }
        if (location.pathname.includes('view-brand')) {
            setType({ type: 'view' });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (dataDetail) {
            formRef?.current?.setFieldsValue(dataDetail);
        }
    }, [dataDetail]);

    if (error) {
        return <NoDataResult />;
    }

    return (
        <>
            <h1 className='text-[28px] mb-[20px]'>{textButton}</h1>
            <BreadCrumb location={location} data={dataDetail} />
            {!dataDetail && type.type !== 'add' ? (
                <BrandDetailSkeleton />
            ) : (
                <div className='max-w-[800px] mx-auto lg:mx-[40px]'>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout='horizontal'
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label='Tên hãng xe'
                            name='ten'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập tên hãng xe!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label='Ảnh' valuePropName='fileList'>
                            <Upload
                                {...props}
                                listType='picture-card'
                                accept='.png,.jpg,.jpeg'
                                beforeUpload={() => {
                                    return false;
                                }}
                            >
                                <div>
                                    {dataDetail && dataDetail.logo ? (
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_URL_UPLOAD +
                                                dataDetail.logo
                                            }
                                            alt='avatar'
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                padding: '0 8px',
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                                Upload
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Upload>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            {loading ? (
                                <SpinComponent />
                            ) : (
                                <Button type='primary' htmlType='submit'>
                                    {textButton}
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
};

export default AddBrand;
