import {
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, InputRef, Popconfirm, Space, Table } from 'antd';
import {
    ColumnsType,
    ColumnType,
    FilterConfirmProps,
} from 'antd/es/table/interface';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { IBrand } from '../../models/brand';

interface BrandsProps {
    brands: IBrand[];
    loading: boolean;
    setSearchKeyword?: Function;
    handleDeleteBrand: (key: number | string) => void;
}

type DataIndex = keyof IBrand;

const Brands: React.FC<BrandsProps> = ({
    brands,
    loading,
    handleDeleteBrand,
}) => {
    const navigate = useNavigate();
    const tableData = brands?.map((brand) => ({
        ...brand,
        key: brand?.id,
    }));
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getDetailHandler = (type: any, slug: string) => {
        navigate(`/brands/${type}-brand/${slug}`);
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex,
        title: string
    ): ColumnType<IBrand> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Tìm kiếm theo ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size='small'
                        style={{ width: 90 }}
                    >
                        Khôi phục
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            close();
                        }}
                    >
                        Đóng
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]!.toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<IBrand> = [
        {
            title: 'Tên hãng xe',
            key: 'ten',
            sorter: (a, b) => {
                if (a.ten < b.ten) {
                    return -1;
                }
                if (a.ten > b.ten) {
                    return 1;
                }
                return 0;
            },
            width: '50%',
            ...getColumnSearchProps('ten', 'Tên'),
            render: (text) => <span className='capitalize'>{text.ten}</span>,
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            width: '30%',
        },
        {
            title: 'Hành động',
            key: 'action',
            fixed: 'right',
            width: 140,
            render: (record) => (
                <Space size='middle' className='ant-table-action'>
                    <Popconfirm
                        title={
                            <>
                                <p className='font-medium'>
                                    Xóa hãng xe {record.ten}
                                </p>
                                <p className='text-[red] font-semibold'>
                                    Xóa hãng xe sẽ xóa toàn bộ sản phẩm thuộc
                                    hãng xe này !!!
                                </p>
                                <p>Bạn có chắc chắn muốn tiếp tục?</p>
                            </>
                        }
                        okText='Đồng ý'
                        cancelText='Không'
                        placement='left'
                        onConfirm={() => handleDeleteBrand(record?.id)}
                    >
                        <Button>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                    <Button
                        onClick={() => {
                            getDetailHandler('edit', record.slug);
                        }}
                    >
                        Sửa
                    </Button>
                </Space>
            ),
        },
    ];
    const data: IBrand[] = brands && tableData;

    return (
        <>
            <>
                <div className='mb-[12px] text-right'>
                    <Button
                        type='primary'
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                            navigate('/brands/add-brand');
                        }}
                    >
                        Thêm
                    </Button>
                </div>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={data ? data : []}
                    pagination={false}
                />
            </>
        </>
    );
};

export default Brands;
