import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCurrentUser } from '../../api/auth';

const ProtectedRoute = () => {
    const location = useLocation();
    const auth = getCurrentUser();

    return auth ? (
        <Outlet />
    ) : (
        <Navigate to='/login' state={{ from: location }} replace />
    );
};

export default ProtectedRoute;
