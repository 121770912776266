import { useRoutes } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout';
import MainLayout from '../layout/MainLayout';
import { authRoute } from './authRoute';
import { vendorRoutes } from './vendorRoutes';

export const routeConfig = [
    {
        element: <MainLayout />,
        children: vendorRoutes,
    },
    {
        element: <AuthLayout />,
        children: authRoute,
    },
];

const RouterManager = () => {
    return useRoutes(routeConfig);
};

export default RouterManager;
