import { message } from 'antd';
import { client } from './client';

export const getProductList = async (
    page?: number,
    size?: number,
    searchKey?: string,
    isDeleted?: boolean
) => {
    const res = await client.get('/products', {
        params: {
            page,
            size,
            searchKey,
            isDeleted,
        },
    });

    return res.data.data;
};

export const getProductBySlug = async (slug?: string) => {
    const res = await client.get(`products/${slug}`);

    return res.data.data;
};

export const createProduct = async (value: any) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post('/products', value, {
        headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'multipart/form-data',
        },
    });

    if (res.data.status) {
        message.success(res.data.message);
    } else {
        message.error(res.data.message);
    }

    return res.data.data;
};

export const updateProduct = async (value: any, slug: any) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.post(`/products/${slug}`, value, {
        headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'multipart/form-data',
        },
    });

    if (res.data.status) {
        message.success(res.data.message);
    } else {
        message.error(res.data.message);
    }

    return res.data.data;
};

export const deleteProduct = async (key: number | string) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const res = await client.delete(`/products/${key}`, {
        headers: { Authorization: 'Bearer ' + user.token },
    });

    return res.data.data;
};
