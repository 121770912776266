import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
    location: any;
    data?: any;
}

const BreadCrumb: FC<BreadcrumbProps> = ({ location, data }) => {
    const changeBreadcrumbValue = (value: string | null) => {
        if (value) {
            if (value === 'products') {
                return 'Quản lý sản phẩm';
            }
            if (value === 'brands') {
                return 'Quản lý thương hiệu';
            }
            if (value === 'add-product') {
                return 'Thêm sản phẩm';
            }
            if (value === 'edit-product') {
                return 'Sửa sản phẩm';
            }
            if (value === 'backup-product') {
                return 'Khôi phục sản phẩm';
            }
            if (value === 'add-brand') {
                return 'Thêm thương hiệu';
            }
            if (value === 'edit-brand') {
                return 'Sửa thương hiệu';
            }
            if (value === 'backup-brand') {
                return 'Khôi phục thương hiệu';
            }
            if (data && data.slug === value) {
                return data.brand ? `${data.brand.ten} ` + data.ten : data.ten;
            }
        }
    };
    const breadCrumbView = () => {
        const { pathname } = location;
        const pathnames = pathname.split('/').filter((item: any) => item);
        return (
            <div className='my-4'>
                <Breadcrumb>
                    {pathnames.length > 0 ? (
                        <Breadcrumb.Item>
                            <Link to='/'>Home</Link>
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                    )}
                    {pathnames.map((name: any, index: number) => {
                        const routeTo = `/${pathnames
                            .slice(0, index + 1)
                            .join('/')}`;
                        const isLast = index === pathnames.length - 1;
                        return isLast || routeTo.includes('edit') ? (
                            <Breadcrumb.Item key={name + index}>
                                {changeBreadcrumbValue(name)}
                            </Breadcrumb.Item>
                        ) : (
                            <Breadcrumb.Item key={name + index}>
                                <Link
                                    to={`${routeTo}`}
                                    className='hover:bg-[unset]'
                                >
                                    {changeBreadcrumbValue(name)}
                                </Link>
                            </Breadcrumb.Item>
                        );
                    })}
                </Breadcrumb>
            </div>
        );
    };

    return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
