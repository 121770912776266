import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Button, Layout } from 'antd';
import useInnerWidth from '../hooks/useInnerWidth';
import Sidebar from '../components/sidebar';
import { Outlet } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

const { Sider, Header, Content } = Layout;

const MainLayout = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const menuRef = useRef<any>(null);
    const { isLaptop } = useInnerWidth();

    useEffect(() => {
        const handler = (e: { target: any }) => {
            if (!menuRef?.current?.contains(e.target)) {
                setOpenMenu(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    return (
        <Layout>
            <Sider
                ref={menuRef}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: isLaptop ? 0 : openMenu ? 0 : '-200px',
                    top: 0,
                    bottom: 0,
                    zIndex: '999',
                }}
            >
                <Sidebar />
            </Sider>
            <Layout>
                {!isLaptop ? (
                    <Header>
                        <Button
                            className='bg-transparent outline-0 border-0'
                            onClick={() => setOpenMenu(true)}
                        >
                            <MenuOutlined className='text-white text-[28px]' />
                        </Button>
                    </Header>
                ) : null}

                <Content
                    className='main-content'
                    style={{
                        marginLeft: isLaptop ? '200px' : openMenu ? '200px' : 0,
                        paddingBottom: '140px',
                    }}
                >
                    <Suspense fallback={null}>
                        <Outlet />
                    </Suspense>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
