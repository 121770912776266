import { Pagination } from 'antd';
import React from 'react';

interface PaginationComponentProps {
    totalItem: number;
    page: number;
    showSize?: boolean;
    handleShowSizeChange?: (current: number, pageSize: number) => void;
    handleChangePage: (current: number) => void;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
    totalItem,
    page,
    showSize,
    handleShowSizeChange,
    handleChangePage,
}) => {
    return (
        <div className='mt-[12px] text-right'>
            <Pagination
                defaultCurrent={1}
                current={page}
                total={totalItem}
                showSizeChanger={showSize ?? false}
                showQuickJumper
                onShowSizeChange={handleShowSizeChange}
                onChange={handleChangePage}
            />
        </div>
    );
};

export default PaginationComponent;
