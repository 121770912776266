import { Skeleton, Space } from 'antd';
import DetailSkeleton from './DetailSkeleton';

const BrandDetailSkeleton = () => {
    return (
        <div className='max-w-[800px] mx-auto'>
            <Space direction='vertical' className='w-full' size={24}>
                <DetailSkeleton />
                <div className='flex justify-start gap-4 custom-skeleton'>
                    <Skeleton.Input active block />
                    <Skeleton.Image active />
                </div>
            </Space>
        </div>
    );
};

export default BrandDetailSkeleton;
