import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ForbiddenPage = () => {
    const navigate = useNavigate();
    return (
        <Result
            status='404'
            title='404'
            subTitle='Xin lỗi, trang bạn tìm kiếm không tồn tại.'
            extra={
                <Button type='primary' onClick={() => navigate('/')}>
                    Back Home
                </Button>
            }
        />
    );
};

export default ForbiddenPage;
