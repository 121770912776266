import React from 'react';
import ForbiddenPage from '../components/common/404';
import ProtectedRoute from '../components/route/ProtectedRoute';
import DeletedBrandsPage from '../pages/backup/DeletedBrandsPage';
import DeletedProductsPage from '../pages/backup/DeletedProductsPage';
import AddBrandPage from '../pages/brand/AddBrandPage';
import BrandsPage from '../pages/brand/BrandsPage';
const HomePage = React.lazy(() => import('../pages/HomePage'));
const ProductsPage = React.lazy(() => import('../pages/product/ProductsPage'));
const AddProductPage = React.lazy(
    () => import('../pages/product/AddProductPage')
);

export const vendorRoutes = [
    {
        element: <ProtectedRoute />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/products',
                element: <ProductsPage />,
            },
            {
                path: '/products/add-product',
                element: <AddProductPage />,
            },
            {
                path: '/brands',
                element: <BrandsPage />,
            },
            {
                path: '/brands/add-brand',
                element: <AddBrandPage />,
            },
            {
                path: '/backup-products',
                element: <DeletedProductsPage />,
            },
            {
                path: '/backup-brands',
                element: <DeletedBrandsPage />,
            },
            {
                path: '/products/edit-product/:slug',
                element: <AddProductPage />,
            },
            {
                path: '/brands/edit-brand/:slug',
                element: <AddBrandPage />,
            },
        ],
    },
    {
        path: '401',
        element: <p>No Authorization Found</p>,
    },
    {
        path: '*',
        element: <ForbiddenPage />,
    },
];
